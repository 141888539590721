var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(!_vm.showSuccess && !_vm.showContractPriceManagementCompanyAirports)?[_c('b-card',{staticClass:"mb-0",attrs:{"body-class":"pb-0"}},[_c('h2',[_vm._v(_vm._s(_vm.$t('service.contract_price_management')))])]),_c('app-data-table',{ref:"contractsListRef",attrs:{"table-name":"cpm","fields":_vm.tableColumns,"items":_vm.product.negotiatedRates,"sort-by":_vm.sortBy,"small":_vm.$store.getters['app/lgAndDown'],"actions-table":_vm.$route.params.typology === 'fuel' ? { showEdit: _vm.$can('NEGOTIATED_RATE_EDIT') } : null,"top-table":{
        showPerPage: _vm.$can('NEGOTIATED_RATE_VIEW'),
        showSearch: _vm.$can('NEGOTIATED_RATE_VIEW'),
        disabled: _vm.negotiatedRatesLoading,
        searchFilterOptions: _vm.searchFilterOptions,
      },"busy":_vm.negotiatedRatesLoading,"bottom-table":{ totalCount: _vm.dataMeta.totalItems }},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"table-select-per-page":function($event){;(_vm.perPage = $event), _vm.fetchNegotiatedRates()},"table-search-text":function($event){return _vm.fetchNegotiatedRates($event)},"edit":function($event){return _vm.editContractPriceManagementCompanyAirports($event.item)}},scopedSlots:_vm._u([{key:"cell(companyName)",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('span',[_vm._v(_vm._s(item.companyName))])])]}},{key:"cell(intracommunityVatNumber)",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('span',[_vm._v(_vm._s(item.intracommunityVatNumber))])])]}},{key:"cell(cancellationPolicyConditions)",fn:function(ref){
      var item = ref.item;
return _vm._l((item.cancellationPolicyConditions),function(condition){return _c('div',{key:condition.id},[_c('span',[_vm._v(" "+_vm._s(_vm._f("enumTranslate")(condition.ifClause,'cancellation_policy_condition_if'))+" "),_c('strong',{staticClass:"condition-rule"},[_vm._v(_vm._s(_vm._f("enumTranslate")(condition.ruleClause,'cancellation_policy_condition_rule')))]),_vm._v(" "+_vm._s(_vm.$t('cancellation_policy.condition.with_rate'))+" "),_c('b-badge',{attrs:{"pill":"","variant":"light-primary"}},[_vm._v(_vm._s(condition.priceRate)+"%")])],1)])})}},{key:"cell(priceManagementCategory)",fn:function(ref){
      var item = ref.item;
return [(item.priceManagementCategory)?_c('div',{staticClass:"text-truncate"},[(item.priceManagementCategory.basePriceMode === 'currency')?_c('span',[_vm._v(" "+_vm._s(_vm._f("priceFormat")(item.priceManagementCategory.basePriceExcludingTaxes))+" ")]):_vm._e(),(item.priceManagementCategory.basePriceMode === 'percent')?_c('span',[_vm._v(" "+_vm._s(_vm._f("percentFormat")(item.priceManagementCategory.basePriceExcludingTaxes))+" ")]):_vm._e()]):_vm._e()]}}],null,false,931162653)}),_c('b-card',{staticClass:"mb-0"},[(_vm.$route.name === 'productShow')?_c('footer-form-service',{attrs:{"previous":""},on:{"click:previous":function($event){return _vm.$emit('previous-tab')}}}):_c('footer-form-service',{attrs:{"previous":"","next":""},on:{"click:previous":function($event){return _vm.$emit('previous-tab')},"click:next":function($event){_vm.showSuccess = true}}})],1)]:_vm._e(),(!_vm.showSuccess && _vm.showContractPriceManagementCompanyAirports)?_c('ContractPriceManagementCompanyAirports',{attrs:{"negotiated-rate":_vm.selecetedNegotiatedRate,"product-id":_vm.product.id,"variant-id":_vm.product.mainVariant.id},on:{"show-company-airports-price-management":function($event){_vm.showContractPriceManagementCompanyAirports = $event}}}):_vm._e(),(_vm.showSuccess)?_c('form-success',{on:{"click:back":function($event){_vm.showSuccess = false}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }