<template>
  <section>
    <template v-if="!showSuccess && !showContractPriceManagementCompanyAirports">
      <b-card class="mb-0" body-class="pb-0">
        <h2>{{ $t('service.contract_price_management') }}</h2>
      </b-card>
      <!-- thead-class="text-nowrap" -->
      <!-- :actions-title="$t('negotiated_rate.price_management_category')" -->
      <app-data-table
        ref="contractsListRef"
        table-name="cpm"
        :fields="tableColumns"
        :items="product.negotiatedRates"
        :sort-by.sync="sortBy"
        :small="$store.getters['app/lgAndDown']"
        :actions-table="$route.params.typology === 'fuel' ? { showEdit: $can('NEGOTIATED_RATE_EDIT') } : null"
        :top-table="{
          showPerPage: $can('NEGOTIATED_RATE_VIEW'),
          showSearch: $can('NEGOTIATED_RATE_VIEW'),
          disabled: negotiatedRatesLoading,
          searchFilterOptions,
        }"
        :busy="negotiatedRatesLoading"
        :bottom-table="{ totalCount: dataMeta.totalItems }"
        @table-select-per-page=";(perPage = $event), fetchNegotiatedRates()"
        @table-search-text="fetchNegotiatedRates($event)"
        @edit="editContractPriceManagementCompanyAirports($event.item)"
      >
        <template #cell(companyName)="{ item }">
          <div class="text-truncate">
            <span>{{ item.companyName }}</span>
          </div>
        </template>

        <template #cell(intracommunityVatNumber)="{ item }">
          <div class="text-truncate">
            <span>{{ item.intracommunityVatNumber }}</span>
          </div>
        </template>

        <template #cell(cancellationPolicyConditions)="{ item }">
          <div v-for="condition in item.cancellationPolicyConditions" :key="condition.id">
            <span>
              {{ condition.ifClause | enumTranslate('cancellation_policy_condition_if') }}
              <strong class="condition-rule">{{ condition.ruleClause | enumTranslate('cancellation_policy_condition_rule') }}</strong>
              {{ $t('cancellation_policy.condition.with_rate') }}
              <b-badge pill variant="light-primary">{{ condition.priceRate }}%</b-badge>
            </span>
          </div>
        </template>

        <template #cell(priceManagementCategory)="{ item }">
          <div v-if="item.priceManagementCategory" class="text-truncate">
            <span v-if="item.priceManagementCategory.basePriceMode === 'currency'">
              {{ item.priceManagementCategory.basePriceExcludingTaxes | priceFormat }}
            </span>
            <span v-if="item.priceManagementCategory.basePriceMode === 'percent'">
              {{ item.priceManagementCategory.basePriceExcludingTaxes | percentFormat }}
            </span>
          </div>
        </template>
      </app-data-table>
      <b-card class="mb-0">
        <footer-form-service v-if="$route.name === 'productShow'" previous @click:previous="$emit('previous-tab')" />
        <footer-form-service v-else previous next @click:previous="$emit('previous-tab')" @click:next="showSuccess = true" />
      </b-card>
    </template>
    <ContractPriceManagementCompanyAirports
      v-if="!showSuccess && showContractPriceManagementCompanyAirports"
      :negotiated-rate="selecetedNegotiatedRate"
      :product-id="product.id"
      :variant-id="product.mainVariant.id"
      @show-company-airports-price-management="showContractPriceManagementCompanyAirports = $event"
    />
    <form-success v-if="showSuccess" @click:back="showSuccess = false" />
  </section>
</template>

<script>
import { defineComponent, ref, inject } from '@vue/composition-api'
import { mapFields } from 'vuex-map-fields'

import AppDataTable from '@/components/AppDataTable.vue'
import FooterFormService from '../component/FooterFormService.vue'
import FormSuccess from './FormSuccess.vue'

export default defineComponent({
  name: 'ContractPriceManagement',

  components: {
    FooterFormService,
    AppDataTable,
    FormSuccess,
    ContractPriceManagementCompanyAirports: () => import('@/views/product/tab/ContractPriceManagementCompanyAirports.vue'),
  },

  setup(_props, ctx) {
    const { $i18n } = ctx.root

    const product = inject('product')

    const contractsListRef = ref(null)

    const filtersCount = ref(0)
    const negotiatedRatesLoading = ref(false)
    const showContractPriceManagementCompanyAirports = ref(false)
    const searchFilterOptions = [{ text: $i18n.t('negotiated_rate.company_name'), value: 'companyName' }]
    const perPage = ref(10)
    const currentPage = ref(1)
    const formMode = ref(null)
    const showSuccess = ref(false)
    const selecetedNegotiatedRate = ref(null)

    const dataMeta = ref({
      totalItems: 0,
      firstPage: 1,
      previousPage: null,
      nextPage: null,
      lastPage: null,
    })

    const tableColumns = [
      { key: 'companyName', label: $i18n.t('negotiated_rate.company'), sortable: true },
      { key: 'intracommunityVatNumber', label: $i18n.t('negotiated_rate.intra_community_vat'), sortable: true },
      { key: 'cancellationPolicyConditions', label: $i18n.t('negotiated_rate.cancellation_policy_conditions') },
      { key: 'priceManagementCategory', label: $i18n.t('negotiated_rate.price_management_category') },
    ]

    negotiatedRatesLoading.value = true
    product.value.fetchNegotiatedRates({}, ctx.root.$route.params.service_id, ctx.root.$route.query.vid).then(res => {
      dataMeta.value = res
      negotiatedRatesLoading.value = false
    })

    const fetchNegotiatedRates = search => {
      const queryParams = {
        page: currentPage.value,
        numberOfItemsPerPage: perPage.value,
        companyName: search || null,
      }
      product.value.fetchNegotiatedRates(queryParams).then(res => {
        dataMeta.value = res
      })
    }

    // TODO: hot fix, see later...
    const fetchNegotiatedRatesTimeout = async () => {
      setTimeout(() => {
        fetchNegotiatedRates()
      }, 500)
    }

    const editContractPriceManagementCompanyAirports = negotiatedRate => {
      selecetedNegotiatedRate.value = negotiatedRate
      showContractPriceManagementCompanyAirports.value = true
    }

    return {
      product,
      formMode,
      dataMeta,
      tableColumns,
      contractsListRef,
      filtersCount,
      negotiatedRatesLoading,
      showContractPriceManagementCompanyAirports,
      searchFilterOptions,
      perPage,
      currentPage,
      fetchNegotiatedRates,
      fetchNegotiatedRatesTimeout,
      editContractPriceManagementCompanyAirports,
      showSuccess,
      selecetedNegotiatedRate,
    }
  },

  deactivated() {
    this.showSuccess = false
  },

  computed: {
    // TODO: use searchFieldOptions instead of searchFilterOptions ?
    // ...mapGetters('negotiatedRate', ['searchFieldOptions']),

    ...mapFields('negotiatedRate', ['paginate.page', 'paginate.numberOfItemsPerPage', 'dataTableSort.sortBy', 'dataTableSort.sortDesc', 'searchField']),
  },
})
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 65px;
}
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
[dir] .form-label-group {
  margin-bottom: 0;
}

.contract-price-management:not(:last-child) {
  border-bottom: 1px solid #e0e0e0;
}

.condition-rule {
  color: #28c76f;
}
</style>
