<template>
  <section class="h-100 flex-column d-flex align-items-center justify-content-center py-2">
    <font-awesome-icon icon="thumbs-up" class="text-success mb-3" size="6x" />
    <h3>{{ $t('service.successfully_added') }}</h3>

    <b-button variant="flat-primary" @click="$emit('click:back')">
      {{ $t('action.back') }}
    </b-button>

    <b-button :to="{ name: 'services' }">
      {{ $t('service.return_to_list') }}
    </b-button>
  </section>
</template>

<script>
export default {
  name: 'FormSuccess',
}
</script>
